import { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CarRentalOutlined from "@mui/icons-material/CarRentalOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SecurityIcon from "@mui/icons-material/Security";




const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Link>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  // Get from session: username, roles, avatar
  const username = sessionStorage.getItem("username");
  const roles_array = sessionStorage.getItem("role");
  const avatar = sessionStorage.getItem("avatar");

  // Parse roles and join the array by -
  const roles = JSON.parse(roles_array).join(" - ");

  const roleCheck = (roles) => {
    try {
      const userRoles = JSON.parse(sessionStorage.getItem("role"));
      const hasRole = roles.length === 0 || roles.some((role) => userRoles.includes(role));
      console.log('Checking roles:', roles, 'User roles:', userRoles, 'Has role:', hasRole);
      return hasRole;
    } catch (e) {
      console.error('Error parsing roles from session storage', e);
      return false;
    }
  };
  
  const menuItems = [
    { header: "General" },
    { title: "Dashboard", to: "/", icon: <HomeOutlinedIcon />, roles: [] },
  
    { title: "Team", to: "/team", icon: <SecurityIcon />, roles: [] },
    { title: "Users", to: "/contacts", icon: <PeopleOutlinedIcon />, roles: [] },
    { title: "Payments", to: "/payments", icon: <ReceiptOutlinedIcon />, roles: [] },

    { header: "Map" },
    { title :"Shapes", to: "/addShape", icon: <CarRentalOutlined />, roles: [] },
    { title: "Map", to: "/map", icon: <MapOutlinedIcon />, roles: [] },
  
    { header: "Blog" },
    { title: "Articles", to: "/articles", icon: <DescriptionOutlinedIcon />, roles: [] },
    { title: "New Article", to: "/newarticle", icon: <AddOutlinedIcon />, roles: [] },
    { header: "Support" },
    { title: "Email", to: "/email", icon: <EmailOutlinedIcon />, roles: [] },
    { title: "Notification", to: "/notification", icon: <NotificationsOutlinedIcon />, roles: [] },
    { header: "Settings" },
    { title: "Settings", to: "/settings", icon: <SettingsOutlinedIcon />, roles: [] },
  ];

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img 
                  alt="logo"
                  width="100px"
                  src="/assets/logo.svg"
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
              <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={avatar}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {username}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {roles}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {menuItems.map((item, index) => (
              item.header ? (
                <Typography
                  key={index}
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  {item.header}
                </Typography>
              ) : (
                roleCheck(item.roles) && (
                  <Item
                    key={index}
                    title={item.title}
                    to={item.to}
                    icon={item.icon}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )
              )
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
