import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import EditorJS from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import Warning from '@editorjs/warning';
import List from '@editorjs/list';
import SimpleImage from '@editorjs/simple-image';
import YoutubeEmbed from 'editorjs-youtube-embed';
import { toast } from 'react-toastify';

const Editor = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [picture, setPicture] = useState(null);

    const [editors, setEditors] = useState({
        en: null,
        fr: null,
        ar: null
    });
    const [values, setValues] = useState({});
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [language, setLanguage] = useState('en');
    const initEditorsCalled = useRef(false);

    useEffect(() => {
        setTouched({});
        setErrors({});
    }, [language]);

    const initializeEditor = (language, holder, content) => {
        return new EditorJS({
            holder,
            tools: {
                paragraph: { class: Paragraph, inlineToolbar: true },
                header: { class: Header, inlineToolbar: true },
                warning: { class: Warning, inlineToolbar: true },
                list: { class: List, inlineToolbar: true },
                image: { class: SimpleImage, inlineToolbar: true },
                youtubeEmbed: YoutubeEmbed,
            },
            data: content
        });
    };

    useEffect(() => {
        if (!initEditorsCalled.current) {
            const editors = {
                en: initializeEditor('en', 'editorjs'),
                fr: initializeEditor('fr', 'editorjs2'),
                ar: initializeEditor('ar', 'editorjs3')
            };
            setEditors(editors);
            initEditorsCalled.current = true;
        }
    }, []);

    const translate = async () => {
        // get current lang
        const lang = language;
        // get current editor content
        const content = await editors[lang].save();
        // get name and description from current lang
        let name;
        let description;
        if (lang == 'en') {

       name = values[`name`];
        description = values[`description`];
        }else {
        name = values[`name_${lang}`];
       description = values[`description_${lang}`];

        }

        // put it like : data: {lesson, name, description}, from, to
        let langs = ['fr', 'ar', 'en'];
        // delete current lang from langs
        langs = langs.filter(l => l !== lang);
        // for each lang in langs
        for (let l of langs) {
            // send a request to the api
            const authToken = sessionStorage.getItem("authToken");
            const postData = {
                data: {
                    lesson : content,
                    name,
                    description
                },
                type: 'lesson',
                from: lang,
                to: l,
                authToken
            };
            console.log(postData);
            setLoading(true);
            try {
                const response = await fetch('https://translate.drp-arts.com/translate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });

                const data = await response.json();
                console.log('Response:', data);
                if (response.ok) {
                    // update the values
                    let newContent = data.lesson;
                    if (l === 'en') {
                        setValues((prev) => ({
                            ...prev,
                            [`name`]: data.name,
                            [`description`]: data.description
                        }));
                    } else {
                    setValues((prev) => ({
                        ...prev,
                        [`name_${l}`]: data.name,
                        [`description_${l}`]: data.description  
                    }));
                    }
                  
                    editors[l].blocks.render(newContent);

                    toast.success('Lesson translated successfully');

                    console.log('Data successfully sent to the API');
                } else {
                    toast.error('Failed to translate lesson');
                    console.error('Failed to send data to the API');
                }
            } catch (error) {
                toast.error('Error sending data to the API');
                console.error('Error sending data to the API:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        setTouched((prev) => ({ ...prev, [name]: true }));
        setErrors((prev) => ({ ...prev, [name]: value.trim() === '' ? 'This field is required' : '' }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleLogOutput = async () => {

        // send authToken, name, description, name_fr, description_fr, name_ar, description_ar, editor, editor_fr, editor_ar
        const authToken = sessionStorage.getItem("authToken");
        const postData = {
            authToken,
            title: values.name,
            description: values.description,
            title_fr: values.name_fr,
            description_fr: values.description_fr,
            title_ar: values.name_ar,
            description_ar: values.description_ar,
            content: await editors.en.save(),
            content_fr: await editors.fr.save(),
            content_ar: await editors.ar.save(),
            picture: picture
        };
        console.log(postData);

        setLoading(true);
        try {
            const response = await fetch('https://api.solutionpub.dz/admin/addArticle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            const data = await response.json();
            console.log('Response:', data);
            if (response.ok) {
                toast.success('Lesson added successfully');
             //   navigate(-1);
                console.log('Data successfully sent to the API');
            } else {
                toast.error('Failed to add lesson');
                console.error('Failed to send data to the API');
            }
        } catch (error) {
            toast.error('Error sending data to the API');
            console.error('Error sending data to the API:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <div>
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Picture"
                onBlur={handleBlur}
                onChange= {e => setPicture(e.target.value)}
                value={values.picture}
                name="picture"
                error={!!touched.picture && !!errors.picture}
                helperText={touched.picture && errors.picture}
            />


            <RadioGroup
                row
                aria-label="language"
                name="language"
                value={language}
                onChange={handleLanguageChange}
            >
                <FormControlLabel value="en" control={<Radio />} label="EN" />
                <FormControlLabel value="fr" control={<Radio />} label="FR" />
                <FormControlLabel value="ar" control={<Radio />} label="AR" />
            </RadioGroup>

            <Box
                display={language === 'en' ? 'grid' : 'none'}
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 2" }}
                />
            </Box>

            <Box
                display={language === 'fr' ? 'grid' : 'none'}
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name (French)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name_fr}
                    name="name_fr"
                    sx={{ gridColumn: "span 2" }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description (French)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description_fr}
                    name="description_fr"
                    sx={{ gridColumn: "span 2" }}
                />
            </Box>

            <Box
                display={language === 'ar' ? 'grid' : 'none'}
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name (Arabic)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name_ar}
                    name="name_ar"
                    sx={{ gridColumn: "span 2" }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description (Arabic)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description_ar}
                    name="description_ar"
                    sx={{ gridColumn: "span 2" }}
                />
            </Box>

            <div id="editorjs" style={{ display: language === 'en' ? 'block' : 'none' }}></div>
            <div id="editorjs2" style={{ display: language === 'fr' ? 'block' : 'none' }}></div>
            <div id="editorjs3" style={{ display: language === 'ar' ? 'block' : 'none' }}></div>
            <button 
            // style 
                 // style
                 style={{
                    padding: "10px 30px",
                    backgroundColor: "black",
                    borderRadius: "10px",
                    marginRight: "10px",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px"
                }}
                disabled={loading}
            onClick={handleLogOutput}>Add</button>
            <button
                       style={{
                        padding: "10px 30px",
                        backgroundColor: "black",
                        borderRadius: "10px",
                        marginRight: "10px",
                        color: "white",
                        border: "none",
                        cursor: "pointer",
                        marginTop: "10px"
                    }}
                disabled={loading}
                onClick={translate}>Translate</button>

        </div>
    );
};

export default Editor;
