import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Articles from "./scenes/articles";
import AddArticle from "./scenes/editorAdd";
// lessons
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddShape from "./scenes/shapes";
import EditShape from "./scenes/shapes/edit";
import Lessons from "./scenes/lessons";
// login
import Contacts from "./scenes/contacts";
import Edit from "./scenes/edit";
import Login from "./scenes/login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Map from "./scenes/Map";
import { LoadScript } from '@react-google-maps/api';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authToken, setAuthToken] = useState(""); // Replace with your authentication token logic
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    // Retrieve the authentication token from async storage or session
    const storedAuthToken = sessionStorage.getItem("authToken");
    setAuthToken(storedAuthToken);
  }, []);
  const handleLoadScript = () => {
    setMapLoaded(true);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <ToastContainer />

        <LoadScript
            googleMapsApiKey="AIzaSyDnDFcEd8Z49W8O7H8eNFNQ2-4fK79LMiA"
            onLoad={handleLoadScript}
          >
            {mapLoaded ? (
          authToken ? (
            <>

              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar 
                setAuthToken={setAuthToken}
                setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/" element={<Dashboard />} />

                  <Route path="/edit/:type/:table/:id" element={<Edit />} />

                  <Route path="/team" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/addShape" element={<AddShape />} />
                  <Route path="/editShape/:shapeId" element={<EditShape />} />
                  <Route path="/map" element={<Map />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/addArticle" element={<AddArticle />} />
                </Routes>
              </main>
            </>
          ) : (
            <Login setAuthToken={setAuthToken} />
          )
        ) : (
          <div>Loading...</div>
        )}
      </LoadScript>
    </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
