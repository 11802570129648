import { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from 'react-toastify';

const Login = ({ setAuthToken }) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [error, setError] = useState(null);

    const handleFormSubmit = async (values) => {
        try {
            // Envoyer une demande de connexion au serveur
            const response = await fetch("https://api.solutionpub.dz/admin/auth", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error("Login failed");
            }

            // Récupérer le token d'authentification depuis la réponse
            const { authToken, role, picture, username, name, fname } = await response.json();
            console.log(picture);
            // Stocker le token d'authentification dans sessionStorage
            sessionStorage.setItem("authToken", authToken);
            sessionStorage.setItem("avatar", picture);
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("name", name);
            sessionStorage.setItem("fname", fname);


            sessionStorage.setItem("role", JSON.stringify(role));
            console.log(role);

            console.log(authToken);
            // Mettre à jour le state parent avec le token d'authentification
            setAuthToken(authToken);
            toast.success('Login successful');
        } catch (error) {
            setError("Login failed. Please check your credentials.");
            toast.error('Login failed. Please check your credentials.');
        }
    };

    return (
        <div 
        style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            width: '100vw',
            height: '100vh' 
        }}
        >
        <Box m="20px">
            <Header title="LOGIN" subtitle="Login to your admin panel" />
            {error && <div>{error}</div>}
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Username"
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                value={formikProps.values.identifier}
                                name="identifier"
                                error={!!formikProps.touched.identifier && !!formikProps.errors.identifier}
                                helperText={formikProps.touched.identifier && formikProps.errors.identifier}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="password"
                                label="Password"
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                value={formikProps.values.password}
                                name="password"
                                error={!!formikProps.touched.password && !!formikProps.errors.password}
                                helperText={formikProps.touched.password && formikProps.errors.password}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Login
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
        </div>
    );
};

const checkoutSchema = yup.object().shape({
    identifier: yup.string().required("required"),
    password: yup.string().required("required"),
});

const initialValues = {
    identifier: "",
    password: "",
};

export default Login;
