import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import './style.css';
import { useParams, useNavigate} from "react-router-dom";

const ShapeForm = () => {
  const [iconFile, setIconFile] = useState(null);
    const { shapeId } = useParams();
    const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    authToken: '',
    name: '',
    description: '',
    type: 'marker',
    structure: [],
  });

  useEffect(() => {
    // Fetch shape data for editing
    const fetchShapeData = async () => {
      try {
        const response = await fetch(`https://api.geo-dz.com/api/getShapes?id=${shapeId}`);
        const result = await response.json();
        if (result.success && result.shapes.length > 0) {
          const shape = result.shapes[0];
          setInitialValues({
            authToken: '', // Normally you'd fetch the authToken or get it from context
            name: shape.name,
            description: shape.description,
            type: shape.type,
            structure: JSON.parse(shape.structure),
          });
        } else {
          alert('Failed to fetch shape data.');
        }
      } catch (error) {
        console.error('Error fetching shape data:', error);
        alert('An error occurred while fetching shape data.');
      }
    };

    fetchShapeData();
  }, [shapeId]);

  const handleFileChange = (event) => {
    setIconFile(event.target.files[0]);
  };

  const editShape = async (values) => {
    const formData = new FormData();
    formData.append('authToken', values.authToken);
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('type', values.type);
    formData.append('structure', JSON.stringify(values.structure));
    if (iconFile) {
      formData.append('icon', iconFile);
    }

    try {
      const response = await fetch(`https://api.geo-dz.com/api/editShape/${shapeId}`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        alert('Shape updated successfully!');
      } else {
        alert('Failed to update shape: ' + result.message);
      }
    } catch (error) {
      console.error('Error updating shape:', error);
      alert('An error occurred while updating the shape.');
    }
  };

  return (
    <div className="form-container">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={editShape}
      >
        {({ values }) => (
          <Form className="shape-form">
            <div className="form-group">
              <label htmlFor="authToken">Auth Token</label>
              <Field name="authToken" type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field name="name" type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Field name="description" type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="icon">Icon (PNG, JPG, or WebP)</label>
              <input name="icon" type="file" accept=".png, .jpg, .jpeg, .webp" onChange={handleFileChange} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <Field as="select" name="type" className="form-control">
                <option value="marker">Marker</option>
                <option value="polyline">Polyline</option>
                <option value="polygon">Polygon</option>
              </Field>
            </div>
            <div className="form-group">
              <label>Structure</label>
              <FieldArray name="structure">
                {({ push, remove }) => (
                  <>
                    {values.structure.map((field, index) => (
                      <div key={index} className="structure-item">
                        <Field name={`structure.${index}.name`} placeholder="Field Name" className="form-control" />
                        <Field as="select" name={`structure.${index}.type`} className="form-control">
                          <option value="text">Text</option>
                          <option value="select">Select</option>
                          <option value="file">File</option>
                        </Field>
                        <button type="button" onClick={() => remove(index)}>-</button>
                      </div>
                    ))}
                    <button type="button" onClick={() => push({ name: '', type: 'text' })}>+ Add Field</button>
                  </>
                )}
              </FieldArray>
            </div>
            <button type="submit" className="btn-submit">Update Shape</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShapeForm;
