import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import { Formik, Form, Field } from 'formik';
import './style.css'; // Ensure the correct path for your CSS file

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const center = {
  lat: 36.0732,
  lng: 4.76108,
};

const MapComponent = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [shapes, setShapes] = useState([]);
  const [selectedShape, setSelectedShape] = useState(null);
  const [currentShape, setCurrentShape] = useState(null);
  const [polygonVertices, setPolygonVertices] = useState([]);
  const [markerHistory, setMarkerHistory] = useState([]); // for undo/redo
  const [redoStack, setRedoStack] = useState([]); // for redo actions
  const [done, setDone] = useState(false); // for polygon creation
  const [marker, setMarker] = useState(null);
  const [A, setA] = useState(null);
  const [B, setB] = useState(null);
  const [AorB, setAorB] = useState("A");
  const [data, setData] = useState([]);

  // Fetch categories when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.geo-dz.com/admin/categories_options?authToken=tGsWYFc4oInn');
        const result = await response.json();
        if (result.success) {
          setCategories(result.categories);
        } else {
          alert('Failed to fetch categories.');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        alert('An error occurred while fetching categories.');
      }
    };

    fetchCategories();
  }, []);

  // Fetch shapes based on the selected category
  useEffect(() => {
    if (selectedCategory) {

  

      const fetchShapes = async () => {
        try {
          const response = await fetch(`https://api.geo-dz.com/admin/getShapes?authToken=tGsWYFc4oInn&category_id=${selectedCategory}`);
          const result = await response.json();
          if (result.success) {
            console.log('Shapes:', result.shapes);
            setShapes(result.shapes);
          } else {
            alert('Failed to fetch shapes.');
          }
        } catch (error) {
          console.error('Error fetching shapes:', error);
          alert('An error occurred while fetching shapes.');
        }
      };

      fetchShapes();
    }
  }, [selectedCategory]);


  useEffect(() => {
    if (selectedShape) {

      const fetchData = async () => {
        try {
          const response = await fetch(`https://api.geo-dz.com/admin/getData?authToken=tGsWYFc4oInn&shapeId=${selectedShape.id}`);
          const result = await response.json();
          if (result.success) {
            console.log('Shape data:', result.data);
          } else {
            alert('Failed to fetch shape data.');
          }
        } catch (error) {
          console.error('Error fetching shape data:', error);
          alert('An error occurred while fetching shape data.');
        }
      };

      fetchData();
    }
  }, [selectedShape]);


  const handleShapeClick = (shape) => {
    setSelectedShape(shape);
    setCurrentShape(shape);
    setPolygonVertices([]);
    setMarkerHistory([]); // Reset history on new shape
    setRedoStack([]); // Reset redo on new shape
    setDone(false); // Reset done state
  };

  const handleMapClick = (event) => {
    const latLng = event.latLng.toJSON();

    if (currentShape?.type === 'marker') {
      setMarker(latLng);
    }
    
    if (currentShape?.type === 'polyline') {
      if (AorB == "A") {
        setA(latLng);
        setAorB("B");
      } else {
        setB(latLng);
        setAorB("A");
      }

    }

    if (currentShape?.type === 'polygon' && !done) {
      setPolygonVertices((prev) => [...prev, latLng]);
      setMarkerHistory((prev) => [...prev, latLng]);
      setRedoStack([]); // Clear redo stack on new action
    }
  };

  // Handle undo (Ctrl+Z) and redo (Ctrl+Y)
  const handleKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === 'z') {
        // Undo (Ctrl+Z)
        if (markerHistory.length > 0) {
          const newHistory = [...markerHistory];
          const lastMarker = newHistory.pop();
          setPolygonVertices(newHistory);
          setMarkerHistory(newHistory);
          setRedoStack((prev) => [...prev, lastMarker]); // Add to redo stack
        }
      } else if (event.ctrlKey && event.key === 'y') {
        // Redo (Ctrl+Y)
        if (redoStack.length > 0) {
          const newRedoStack = [...redoStack];
          const restoredMarker = newRedoStack.pop();
          setPolygonVertices((prev) => [...prev, restoredMarker]);
          setMarkerHistory((prev) => [...prev, restoredMarker]);
          setRedoStack(newRedoStack);
        }
      }
    },
    [markerHistory, redoStack]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const handleDoneClick = () => {
    setDone(true); // Mark polygon as completed
  };

  const parseStructure = (structureString) => {
    try {
      const parsedStructure = JSON.parse(structureString);
      return typeof parsedStructure === 'string' ? JSON.parse(parsedStructure) : parsedStructure;
    } catch (error) {
      console.error('Error parsing structure:', error);
      return [];
    }
  };

  return (
    <div className="map-container">
      <h2>Categories</h2>
      <div className="shape-list">
        {Object.entries(categories).map(([id, name]) => (
          <div
            key={id}
            className="shape-item"
            style={{ backgroundColor: selectedCategory === id ? 'lightblue' : 'white' }}
            onClick={() => setSelectedCategory(id)}
          >
            {name}
          </div>
        ))}
      </div>

      <h2>Shapes</h2>
      <div className="shape-list">
        {shapes.map((shape) => (
          <div
            className="shape-item"
            style={{ backgroundColor: selectedShape?.id === shape.id ? 'lightblue' : 'white' }}
            key={shape.id}
            onClick={() => handleShapeClick(shape)}
          >
            <img src={`https://api.geo-dz.com/public/icons/${shape.id}.png`} alt={shape.name} />
            {shape.name}
          </div>
        ))}
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={handleMapClick}
      >
        {currentShape?.type === 'polygon' &&
          polygonVertices.map((vertex, index) => (
            <Marker key={index} position={vertex} />
          ))}

{currentShape?.type == 'polygon' && data.map((vertex, index) => (
            <Polygon key={index} paths={vertex} />
          ))}


        {currentShape?.type === 'polygon' && done && polygonVertices.length > 0 && (
          <Polygon
            paths={polygonVertices}
            options={{
              fillColor: 'blue',
              fillOpacity: 0.4,
              strokeColor: 'blue',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />
        )}
                {marker && currentShape?.type === 'marker' && <Marker position={marker} />}

        {A && currentShape?.type === 'polyline' && <Marker 
        // text : A 
        
        position={A} />}
        {B && currentShape?.type === 'polyline' && <Marker position={B} />}



      </GoogleMap>

      {currentShape?.type === 'polygon' && !done && (
        <button className="btn-done" onClick={handleDoneClick}>
          Done
        </button>
      )}

      {selectedShape && (
        <div className="form-container">
          <Formik
            enableReinitialize
            initialValues={{
              structure: parseStructure(selectedShape.structure) || [],
            }}
            onSubmit={async (values) => {
              console.log('Submitted values:', values);
              let position;
              let data = values.structure;
              if (currentShape?.type === 'marker') {
          console.log('Marker:', marker);
          position = marker;
              } else if (currentShape?.type === 'polyline') {
          console.log('A:', A);
          console.log('B:', B);
          position = [A, B];
              }
              // if polygon
              else if (currentShape?.type === 'polygon') {
          console.log('Polygon:', polygonVertices);
          position = polygonVertices;
              }

              const shapeData = {
          authToken: 'tGsWYFc4oInn',
          shapeId: selectedShape.id,
          position: position,
          values: data,
          type: currentShape?.type,
              };

              console.log('Shape data:', JSON.stringify(shapeData));

              try {
          const response = await fetch('https://api.geo-dz.com/admin/submitData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(shapeData),
          });

          const result = await response.json();
          console.log('API response:', result);
              } catch (error) {
          console.error('Error submitting shape:', error);
          alert('An error occurred while submitting the shape.');
              }
            }}
          >
            {({ values }) => (
              <Form className="shape-form">
                {Array.isArray(values.structure) && values.structure.length > 0 ? (
                  values.structure.map((field, index) => (
                    <div key={index} className="form-group">
                      <label htmlFor={`structure.${index}.name`}>{field.name}</label>
                      <Field
                        name={`structure.${index}.value`}
                        type={field.type}
                        className="form-control"
                      />
                    </div>
                  ))
                ) : (
                  <p>No fields to display.</p>
                )}
                <button type="submit" className="btn-submit">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
