import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import './style.css';

const ShapeForm = () => {
  const [iconFile, setIconFile] = useState(null);
  const [categories, setCategories] = useState([]);

  // Fetch categories when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.geo-dz.com/admin/categories_options?authToken=tGsWYFc4oInn');
        const result = await response.json();
        if (result.success) {
          setCategories(result.categories);
        } else {
          console.error('Failed to fetch categories:', result.message);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleFileChange = (event) => {
    setIconFile(event.target.files[0]);
  };

  const addShape = async (values) => {
    const formData = new FormData();
    const authToken = "tGsWYFc4oInn";

    formData.append('authToken', authToken);
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('type', values.type);
    formData.append('structure', JSON.stringify(values.structure));
    formData.append('category', values.category); // Add selected category

    if (iconFile) {
      formData.append('icon', iconFile);
    }

    try {
      const response = await fetch('https://api.geo-dz.com/admin/addShape', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        alert('Shape added successfully!');
      } else {
        alert('Failed to add shape: ' + result.message);
      }
    } catch (error) {
      console.error('Error adding shape:', error);
      alert('An error occurred while adding the shape.');
    }
  };

  return (
    <div className="form-container">
      <Formik
        initialValues={{
          name: '',
          description: '',
          type: 'marker',
          structure: [],
          category: '' // Add category field to initial values
        }}
        onSubmit={addShape}
      >
        {({ values }) => (
          <Form className="shape-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field name="name" type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Field name="description" type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="icon">Icon (PNG, JPG, or WebP)</label>
              <input name="icon" type="file" accept=".png, .jpg, .jpeg, .webp" onChange={handleFileChange} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <Field as="select" name="type" className="form-control">
                <option value="marker">Marker</option>
                <option value="polyline">Polyline</option>
                <option value="polygon">Polygon</option>
              </Field>
            </div>
            <div className="form-group">
              <label htmlFor="category">Category</label>
              <Field as="select" name="category" className="form-control">
                <option value="" label="Select a category" />
                {Object.entries(categories).map(([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Field>
            </div>
            <div className="form-group">
              <label>Structure</label>
              <FieldArray name="structure">
                {({ push, remove }) => (
                  <>
                    {values.structure.map((field, index) => (
                      <div key={index} className="structure-item">
                        <Field name={`structure.${index}.name`} placeholder="Field Name" className="form-control" />
                        <Field as="select" name={`structure.${index}.type`} className="form-control">
                          <option value="text">Text</option>
                          <option value="select">Select</option>
                          <option value="file">File</option>
                        </Field>
                        <button type="button" onClick={() => remove(index)}>-</button>
                      </div>
                    ))}
                    <button type="button" onClick={() => push({ name: '', type: 'text' })}>+ Add Field</button>
                  </>
                )}
              </FieldArray>
            </div>
            <button type="submit" className="btn-submit">Add Shape</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShapeForm;
